import React from 'react'
import { Link } from 'gatsby'
import Fade from "react-reveal/Fade"
import { useSelector, useDispatch } from 'react-redux'
import { cultureDetailSlug } from '../actions'

const WorkGridCulture = (props) => {
    let seeMoreWorkString = "See more"
    const langKey = useSelector(state => state.langKey.language)
    let urlBase
    if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
        urlBase = langKey === "ko" ? "" : `/${langKey}`
    }
    else{
        urlBase = langKey === "ja" ? "" : `/${langKey}`
    }
    const dispatch = useDispatch()
    return(
        <Fade bottom speed={450} delay={25} distance="15%">
        <article className={`thumb ${props.class}`} data-index={props.index}>
            <div className="thumb__Img">
                <img src={props.thumb} srcSet={props.thumbSrcSet} alt="" />    
            </div>
            <div className="thumb__Desc" style={{backgroundColor: `#${props.bgColor}`}}>
                <h2 dangerouslySetInnerHTML={{__html:props.title}} />
                <p>{props.description}</p>
                {/* <p className="thumb__Link">{seeMoreWorkString}</p> */}
                <Link to={`${urlBase}/${props.section}/${props.url}`} className="thumb__Overlay__Link" onClick={()=> dispatch(cultureDetailSlug(props.url))}>View</Link>
            </div>
        </article>
        </Fade>
    )
}


export default WorkGridCulture