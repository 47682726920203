import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Layout from "../layouts/App"
import SEO from "../components/seo"

import WorkGridCulture from '../components/WorkGridCulture'

export default ({pageContext}) => {
    return(
        <Layout>
            <SEO title="Culture" description={pageContext.node.acf.metaPageDescription}/>
            <article>
                <section className="container-fluid-custom-only work_page">
                    <StaticQuery query={graphql`
                            {
                                cultureEN: allWpCulture(filter:{languageCode:{eq:"en"}}) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            date(formatString: "DD/MM/YYYY")
                                            languageCode
                                            content
                                            acf {
                                                cultureExcerpt
                                                cultureImage {
                                                    id
                                                    sourceUrl
                                                    srcSet
                                                    mediaDetails {
                                                        sizes {
                                                          name
                                                          sourceUrl
                                                        }
                                                      }
                                                }
                                            }
                                            related_office {
                                                relatedOffice
                                            }
                                        }
                                    }
                                }
                                cultureJA: allWpCulture(filter:{languageCode:{eq:"ja"}, related_office: {relatedOffice: {ne: "wkseoul"}}}) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            date(formatString: "DD/MM/YYYY")
                                            languageCode
                                            content
                                            acf {
                                                cultureExcerpt
                                                cultureImage {
                                                    id
                                                    sourceUrl
                                                    srcSet
                                                    mediaDetails {
                                                        sizes {
                                                          name
                                                          sourceUrl
                                                        }
                                                      }
                                                }
                                            }
                                            related_office {
                                                relatedOffice
                                            }
                                        }
                                    }
                                }
                                cultureKO: allWpCulture(filter:{languageCode:{eq:"ko"}, related_office: {relatedOffice: {ne: "wktokyo"}}}) {
                                    edges {
                                        node {
                                            id
                                            title
                                            slug
                                            date(formatString: "DD/MM/YYYY")
                                            languageCode
                                            content
                                            acf {
                                                cultureExcerpt
                                                cultureImage {
                                                    id
                                                    sourceUrl
                                                    srcSet
                                                    mediaDetails {
                                                        sizes {
                                                          name
                                                          sourceUrl
                                                        }
                                                      }
                                                }
                                            }
                                            related_office {
                                                relatedOffice
                                            }
                                        }
                                    }
                                }
                            }
                        `}
                        render={ props => {
                            if(pageContext.node.languageCode === 'ja'){
                                let cultureImagery
                                return(
                                    <div className="grids">
                                        {props.cultureJA.edges.map((culture, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`FF0045`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`FF7B00`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            }                                         
                                            {culture.node.acf.cultureImage.mediaDetails.sizes.map(imageSrc => {
                                                for(let i=0; i<5; i++){
                                                    if(imageSrc.name === 'large'){
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }else if(imageSrc.name === 'medium') {
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }  
                                                    i++;                                             
                                                }
                                            })}
                                            return(
                                                <WorkGridCulture
                                                    index={index}
                                                    key={culture.node.id}
                                                    section="culture"
                                                    class={gridClassName}
                                                    thumb={cultureImagery}
                                                    thumbSrcSet={culture.node.acf.cultureImage.srcSet}
                                                    title={culture.node.title}
                                                    description={culture.node.acf.cultureExcerpt}
                                                    bgColor={gridBgColor}
                                                    url={culture.node.slug}
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                            if(pageContext.node.languageCode === 'en'){
                                let cultureImagery
                                return(
                                    <div className="grids">
                                        {props.cultureEN.edges.map((culture, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`FF0045`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`FF7B00`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            } 
                                            {culture.node.acf.cultureImage.mediaDetails.sizes.map(imageSrc => {
                                                for(let i=0; i<5; i++){
                                                    if(imageSrc.name === 'large'){
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }else if(imageSrc.name === 'medium') {
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }  
                                                    i++;                                             
                                                }
                                            })}
                                            if(process.env.GATSBY_OFFICE_NAME === 'WK_SEOUL'){
                                                if(culture.node.related_office.relatedOffice !== 'wktokyo'){
                                                    return(
                                                        <WorkGridCulture
                                                            index={index}
                                                            key={culture.node.id}
                                                            section="culture"
                                                            class={gridClassName}
                                                            thumb={cultureImagery}
                                                            thumbSrcSet={culture.node.acf.cultureImage.srcSet}
                                                            title={culture.node.title}
                                                            description={culture.node.acf.cultureExcerpt}
                                                            bgColor={gridBgColor}
                                                            url={culture.node.slug}
                                                        />
                                                    )
                                                }
                                            }
                                            else{
                                                if(culture.node.related_office.relatedOffice !== 'wkseoul'){
                                                    return(
                                                        <WorkGridCulture
                                                            index={index}
                                                            key={culture.node.id}
                                                            section="culture"
                                                            class={gridClassName}
                                                            thumb={cultureImagery}
                                                            thumbSrcSet={culture.node.acf.cultureImage.srcSet}
                                                            title={culture.node.title}
                                                            description={culture.node.acf.cultureExcerpt}
                                                            bgColor={gridBgColor}
                                                            url={culture.node.slug}
                                                        />
                                                    )
                                                }
                                            }
                                        })
                                    }
                                    </div>
                                )
                            }
                            if(pageContext.node.languageCode === 'ko'){
                                let cultureImagery
                                return(
                                    <div className="grids">
                                        {props.cultureKO.edges.map((culture, index) => {
                                            let gridClassName = [], gridBgColor = [], gridIndex = index
                                            if(gridIndex % 7 === 0) {
                                                gridClassName.push(`doubleSize`)
                                                gridBgColor.push(`FF0045`)
                                            }
                                            else if(gridIndex % 7 === 3) {
                                                gridClassName.push(`fullWidth`)
                                                gridBgColor.push(`FF7B00`)
                                            }
                                            else{
                                                gridClassName.push(`standard`)
                                            }
                                            {culture.node.acf.cultureImage.mediaDetails.sizes.map(imageSrc => {
                                                for(let i=0; i<5; i++){
                                                    if(imageSrc.name === 'large'){
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }else if(imageSrc.name === 'medium') {
                                                        return cultureImagery = imageSrc.sourceUrl
                                                        i = 4;
                                                    }  
                                                    i++;                                             
                                                }
                                            })}
                                            return(
                                                <WorkGridCulture
                                                    index={index}
                                                    key={culture.node.id}
                                                    section="culture"
                                                    class={gridClassName}
                                                    thumb={cultureImagery}
                                                    thumbSrcSet={culture.node.acf.cultureImage.srcSet}
                                                    title={culture.node.title}
                                                    description={culture.node.acf.cultureExcerpt}
                                                    bgColor={gridBgColor}
                                                    url={culture.node.slug}
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                )
                            }
                            
                        }
                        } />
                    <div className="clearfix"></div>
                </section>
            </article>
        </Layout>
    )
}